import styled from 'styled-components';

const App = styled.div`
	background-color: ${(props) => props.theme.background};
	color: ${(props) => props.theme.text};
	display: flex;
	flex-direction: column;
	align-items: center;
  min-height: 100vh;

  .background-image {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    lefT: 0;
    z-index: 0;
    overflow: hidden;

    img {
      height: 100%;
      min-width: 100vw;
      object-fit: cover;
      object-position: center;
      transition: all 500ms;
    }
  }

	article {
		display: flex;
		align-items: center;
		width: 100%;
		flex: 1 0 auto;
    flex-wrap: wrap;
    background-color: rgba(${(props) => props.theme.backgroundRgb}, .90);
    z-index: 1;
    overflow: auto;


		& > section {
			flex: 1 0 50%;
			display: flex;
			align-items: center;
			justify-content: center;
      flex-direction: column;
      position: relative;
      min-width: 380px;
      
      & > .animated {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
		}
	}

	a {
		color: ${(props) => props.theme.accent};
  }
  
  .studio-type {
    display: inline-block;
    font-weight: 600;
    color: ${(props) => props.theme.accent};
    min-width: 450px;
    display: inline-block;
    position: absolute;
  }
/* 
	h1 strong {
    color: ${(props) => props.theme.accent};
    min-width: 450px;
    display: inline-block;
	} */

	.tagline-box { 
    img {
      max-width: 280px;
      padding: ${(props) => props.theme.xlarge} 0;
    }
	}

	footer {
		min-height: 54px;
	}

	input {
		padding: ${(props) => props.theme.large} 0;
		background: none;
		border: 0;
		border-bottom: 1px solid ${(props) => props.theme.highlight};
		color: ${(props) => props.theme.text};

		&:hover, &:active {
			border-bottom-color: ${(props) => props.theme.text};
		}

		&:focus {
			outline: 0;
			border-bottom-color: ${(props) => props.theme.accent};
		}

    &:-internal-autofill-selected {
      background: ${(props) => props.theme.highlight} !important;
    }
	}

	button {
		padding: ${(props) => props.theme.large} ${(props) => props.theme.xlarge};
		background: ${(props) => props.theme.highlight};
		border-radius: 4px;
		color: ${(props) => props.theme.text};
		font-weight: bold;
		border: 0;
		outline: 0;

		&:hover {
			opacity: .95;
		}

		&.primary {
			background: ${(props) => props.theme.accent};
			/* color: ${(props) => props.theme.background}; */
		}
	}

  @media screen and (max-width: 768px) {
    article {
      padding: ${(props) => props.theme.medium};
    }

    .tagline-box {
      width: calc(100% - 16px);
      .animated {
        width: 100%;
      }
    }
  }
`;

export default { App };
