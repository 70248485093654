import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Animated } from 'react-animated-css';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Newsletter from '../../components/Newsletter';
import SignUpBox from '../../components/Signup';
import theme from '../../theme';
import Styles from './styles';

const TYPES = [
	{
		name: 'Recording Studios',
		img: '/recording.jpg',
	},
	{
		name: 'Photography Studios',
		img: '/photography.jpg',
	},
	{
		name: 'Sound Stages',
		img: '/sound-stage.jpg',
	},
	{
		name: 'Production Workspaces',
		img: '/production.jpg',
	},
];

const apollo = new ApolloClient({
	uri: 'https://ci.gostudious.com/graphql',
});

const getStudioType = (type, setType) => {
	const interval = setInterval(() => {
		const item = TYPES.filter((tp) => tp.name === type);
		const index = TYPES.indexOf(item[0]);
		const next = index === TYPES.length - 1 ? TYPES[0].name : TYPES[index + 1].name;
		setType(next);
	}, 5000);

	return interval;
};

const TaglineBox = ({ type }) => (
	<div className="tagline-box">
		<img src="/logo_full.svg" alt="Logo" />
		<h1>
			Rent
			<br />
			{TYPES.map((tp) => (
				<Animated
					animationIn="fadeIn"
					animationOut="fadeOut"
					isVisible={type === tp.name}
					key={tp.name}
					className="studio-type"
					animationInDuration={250}
					animationOutDuration={250}
				>
					<strong>{tp.name}</strong>
				</Animated>
			))}

			<br />
			by the hour.
		</h1>
	</div>
);


function App() {
	const [form, setForm] = useState('newsletter');
	const [type, setType] = useState('Recording Studios');

	useEffect(() => {
		const interval = getStudioType(type, setType);
		return () => {
			clearInterval(interval);
		};
	}, [type]);

	return (
		<ApolloProvider client={apollo}>
			<ThemeProvider theme={theme}>
				<Styles.App>
					{TYPES.map((tp) => (
						<Animated
							animationIn="fadeIn"
							animationOut="fadeOut"
							key={tp.name}
							isVisible={type === tp.name}
							className="background-image"
							animationInDuration={250}
							animationOutDuration={250}
						>
							<img src={tp.img} alt="background" />
						</Animated>
					))}
					<article>
						<section>
							<TaglineBox type={type} />
						</section>
						<section>
							<Animated
								animationIn="bounceIn"
								animationOut="bounceOut"
								isVisible={form === 'newsletter'}
								animationInDuration={250}
								animationOutDuration={50}
							>
								<Newsletter setForm={setForm} />
							</Animated>
							<Animated
								animationIn="bounceIn"
								animationOut="bounceOut"
								isVisible={form === 'beta'}
								animationInDuration={250}
								animationOutDuration={50}
							>
								<SignUpBox setForm={setForm} />
							</Animated>
						</section>
					</article>
				</Styles.App>
			</ThemeProvider>
		</ApolloProvider>

	);
}

export default App;
