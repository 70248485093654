import React, { useRef, useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Styles from './styles';

const addAsBetaTester = (e, hname, email, addSubscriber) => {
	e.preventDefault();
	if (!hname.value) {
		addSubscriber({ variables: { email: email.current.value, beta: true } });
	}
};

const SignUpBox = ({ setForm }) => {
	const email = useRef(null);
	const hname = useRef(null);
	const [addSubscriber, { data }] = useMutation(
		gql`
	    mutation MailingList($email: String!, $beta: Boolean) {
        addToMailingList(email: $email, beta: $beta)
	    }
	  `,
	);
	const [submitted, setSubmitted] = useState('');

	useEffect(() => {
		if (data && data.addToMailingList) {
			setSubmitted(data.addToMailingList);
		}
	}, [data]);

	return (
		<>
			<Styles.SignUp>
				<header>
					<section className="title">
						<h3>Limited Access Beta Release</h3>
						<h2>Coming May 2020</h2>
					</section>
				</header>


				<p><strong>Studio or Artist in the Philadelphia Area?</strong></p>

				<p>
					If you own production workspace that you would like to rent out, or
					if you are an artist seeking a workspace, you are eligable to be a beta tester!
				</p>

				<h4>
					Enter your email address below to request an invitation.
				</h4>

				{submitted && submitted === 'beta' ? (
					<div className="Success">
						<h3>Success!</h3>
						<p>Keep an eye on your inbox for monthly updates!</p>
					</div>
				) : (
					<form className="signup-form" onSubmit={(e) => addAsBetaTester(e, hname, email, addSubscriber, setSubmitted)}>
						<input type="email" placeholder="Email Address" name="email" ref={email} />
						<input className="humon-name" ref={hname} />
						<button type="submit" className="primary">Request Invitation</button>
					</form>
				)}


			</Styles.SignUp>
			<Styles.BetaToggle>
				<button type="button" onClick={() => setForm('newsletter')}>Back</button>
			</Styles.BetaToggle>
		</>
	);
};

export default SignUpBox;
