const theme = {
	// Colors
	card: '#222328',
	background: '#1C1D21',
	backgroundRgb: '28,29,33',
	accent: '#E63946',
	highlight: '#373B44',
	text: '#E8EEF2',
	// Sizes
	small: '4px',
	medium: '8px',
	large: '12px',
	xlarge: '16px',
	xxlarge: '24px',
};

export default theme;
