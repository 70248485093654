import styled from 'styled-components';

const Newsletter = styled.div`
	background: ${(props) => props.theme.card};
	max-width: 420px;
	padding: ${(props) => props.theme.xxlarge};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
  box-shadow: 0 2px 12px rgba(0,0,0,.16);

	h2 {
		margin: 0;
	}

  h3 {
    color: ${(props) => props.theme.accent};
    margin-bottom: 4px;
  }

	.newsletter-form {
		margin: ${(props) => props.theme.xxlarge} 0 0;

		input {
			width: 100%;
		}

		button {
			margin-top: ${(props) => props.theme.xlarge};
		}

    .humon-name {
      display: none;
    }
	}
`;

const BetaToggle = styled.div`
	button {
		margin-top: ${(props) => props.theme.xxlarge};
	}
`;

export default { Newsletter, BetaToggle };
