import React, { useRef, useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import Styles from './styles';

const addToNewsletter = (e, hname, email, addSubscriber) => {
	e.preventDefault();
	if (!hname.value) {
		addSubscriber({ variables: { email: email.current.value } });
	}
};

const Newsletter = ({ setForm }) => {
	const email = useRef(null);
	const hname = useRef(null);
	const [addSubscriber, { data }] = useMutation(
		gql`
	    mutation MailingList($email: String!) {
        addToMailingList(email: $email)
	    }
	  `,
	);
	const [submitted, setSubmitted] = useState('');

	useEffect(() => {
		if (data && data.addToMailingList) {
			setSubmitted(data.addToMailingList);
		}
	}, [data]);

	return (
		<>
			<Styles.Newsletter>
				<h2>Coming Fall 2020</h2>

				<p>
					Studious is a marketplace for studios and other production workspaces,
					where users can find and rent space by the hour.
				</p>
				<h4>
					Enter your email address below to receive monthly updates
					and release news!
				</h4>

				{submitted && submitted === 'subscribed' ? (
					<div className="Success">
						<h3>Success!</h3>
						<p>Keep an eye on your inbox for monthly updates!</p>
					</div>
				) : (
					<form className="newsletter-form" onSubmit={(e) => addToNewsletter(e, hname, email, addSubscriber, setSubmitted)}>
						<input type="email" placeholder="Email Address" name="email" ref={email} />
						<input className="humon-name" ref={hname} />
						<button className="primary" type="submit">Sign Up</button>
					</form>
				)}

			</Styles.Newsletter>
			<Styles.BetaToggle>
				<button onClick={() => setForm('beta')} type="button">Want early access?</button>
			</Styles.BetaToggle>
		</>
	);
};

export default Newsletter;
